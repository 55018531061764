*.--mobileOnly {
    display: none !important;
}

*.--desktopOnly {
    display: flex !important;
}

div[nxAvatar].--desktopOnly,
nx-slider.--desktopOnly,
button.--desktopOnly {
    display: block !important;
}

.azd-margin-left-auto {
    margin-left: auto !important;
}

.azd-margin-right-auto {
    margin-right: auto !important;
}

.azd-margin-top-auto {
    margin-top: auto !important;
}

.azd-margin-bottom-auto {
    margin-bottom: auto !important;
}

.azd-vertical-hr {
    background-color: #d9d9d9;
    width: 1px;
    height: 40px;
    margin: 0 24px;
}

.azd-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.azd-element-in-a-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &.-attachment {
        margin: 16px 0;
        & input {
            display: none;
        }
    }
}

.azd-text-align-center {
    text-align: center;
}

@media screen and (max-width: 1023px) {
    *.--mobileOnly {
        display: flex !important;
    }

    div[nxAvatar].--mobileOnly,
    nx-slider.--mobileOnly,
    button.--mobileOnly {
        display: block !important;
    }

    *.--desktopOnly {
        display: none !important;
    }

    div[nxAvatar].--desktopOnly,
    nx-slider.--desktopOnly,
    button.--desktopOnly {
        display: none !important;
    }
}
