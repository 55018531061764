/* You can add global styles to this file, and also import other style files */
@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/cdk/a11y-prebuilt.css';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'hnrg';
@import 'utilities';

// GENERAL

*:focus {
    outline: none;
}

html,
body {
    height: 100%;
}

azd-layout-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    & azd-footer {
        margin-top: auto;
    }
}

hr {
    width: 100%;
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #d9d9d9;
    padding-top: 0;
    margin: 16px 0;
}

// FORM ELEMENTS

//button[nxButton] {
//    min-width: 280px;
//}

.azd-iconButton {
    border-radius: 24px !important;
    box-shadow: 0 0 24px rgba(0, 122, 179, 0.55);
}

// SHARED COMPONENTS

// SIDEBAR
nx-sidebar.azd-sidebar {
    width: 350px;
    margin-right: 32px;
    height: auto;
    box-shadow: 8px -8px 24px 0 rgba(65, 65, 65, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;

    & p {
        padding: 0 40px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    & .azd-iconButton {
        position: absolute;
        top: 20px;
        right: -20px;
        box-shadow: 0 0 24px rgba(0, 122, 179, 0.55);
    }
}

.azd-modal {
    &__logo {
        width: 180px;
        position: absolute;
        top: 16px;
        left: 16px;
    }

    &__content {
        padding: 16px 0;

        &ModalTitle {
            text-align: center;
            margin-bottom: 32px !important;
            margin-top: 24px !important;
        }
        &PrivacyTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__footer.nx-modal__actions {
        margin-top: 16px;
        margin-bottom: 8px !important;
        border-top: none !important;
        justify-content: space-between !important;
        flex-wrap: wrap;
        padding: 0 40px !important;

        & button {
            margin-top: 16px;
            width: auto !important;

            &:only-of-type {
                width: 100% !important;
            }
        }
    }

    & .nx-modal__content {
        padding: 0 8px !important;
    }
}

.azw-event-type-label {
    font-size: 12px !important;
    margin-bottom: 16px;
    align-self: end;
}

div[nxAvatar] {
    background-color: #007ab3;
}

nx-tab-group {
    & nx-tab-scroll-indicator {
        display: none;
    }
}

.azw-feedbackModal {
    & nx-radio {
        & .nx-radio__label {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width: 1600px) {
    nx-sidebar.azd-sidebar {
        position: relative;
    }
}

@media screen and (max-width: 1023px) {
    .azd-modal {
        &__footer.nx-modal__actions {
            padding: 0 24px;

            & button {
                padding: 16px;
            }
        }
    }
}
